* {
    /* 去除默认边距 */
    margin: 0;
    padding: 0;
    /* 元素计算边框大小 */
    box-sizing: border-box;
}


/*整个滚动条*/

::-webkit-scrollbar {
    width: 1px;
    background-color: rgba(255, 255, 255, 0);
}


/*定义滚动条轨道*/

::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
}


/*定义滑块*/

::-webkit-scrollbar-thumb {
    background-color: #acb1bf00;
}

@font-face {
    font-family: "Arial";
    src: url(../font/Arial.ttf);
}

@font-face {
    font-family: "Playlist";
    src: url(../font/Playlist.otf);
}

@font-face {
    font-family: "SeasideResortNF";
    src: url(../font/SeasideResortNF.ttf);
}

@font-face {
    font-family: "Montserrat";
    src: url(../font/Montserrat.ttf);
}

@font-face {
    font-family: "Lato";
    src: url(../font/Lato.ttf);
}

@font-face {
    font-family: "EDO";
    src: url(../font/Edo.ttf);
}

@font-face {
    font-family: "GlacialIndifference";
    src: url(../font/GlacialIndifference.otf);
}

@font-face {
    font-family: "JennaSue";
    src: url(../font/JennaSue.ttf);
}

@font-face {
    font-family: "Roboto";
    src: url(../font/Roboto.ttf);
}

@font-face {
    font-family: "Acme";
    src: url(../font/Acme.ttf);
}

@font-face {
    font-family: "WildMonth";
    src: url(../font/WildMonth.ttf);
}

@font-face {
    font-family: "VT323";
    src: url(../font/VT323.ttf);
}

@font-face {
    font-family: "Great";
    src: url(../font/Great.otf);
}

@font-face {
    font-family: "Prida01";
    src: url(../font/Prida01.otf);
}

@font-face {
    font-family: "Glacia";
    src: url(../font/Glacial.otf);
}